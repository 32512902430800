import './App.css';
import React, { useEffect, useState } from 'react';

function App() {
  const [currentRoute, setCurrentRoute] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentRoute(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const LinkItem = ({ title, iconColor }: {
    title: string
    iconColor: string
  }) => {
    return (
      <a href={`#${title}`} className='suh-wrapper'>
        <div className='suh'  style={{
          backgroundColor: iconColor,
        }}/>
        <p>{title}</p>
      </a>
    )
  }

  const Home = () => {
    return (
      <div>
        <LinkItem iconColor='#0e5d17' title='schedule' />
        <LinkItem iconColor='#277d00' title='stay' />
        <LinkItem iconColor='#479f4b' title='travel' />
        <LinkItem iconColor='#8BC34A' title='rsvp' />
        <LinkItem iconColor='#CDDC39' title='faq' />
        {/* <LinkItem iconColor='#ceff6a' title='visit' /> */}
      </div>
    );
  };

  const Back = () => <a href="/">home</a>


  const Content = () => {
    switch (currentRoute) {
      case '#schedule':
        return (
          <div>
            <Back />
            <div style={{
              marginTop: "15px",
            }}>
              {/* <EventComponent 
                content="We are so excited to celebrate our wedding with you at Chateau Engalin in Mauvezin, a small village west of Toulouse. We’ve arranged for guests to stay in Lectoure, 30 minutes from the chateau. On Friday, we will host a welcome party in Lectoure, walking distance for guests. For the wedding on Saturday and pool party on Sunday, we’ve organized shuttles from Lectoure to the chateau and back. See you in France!"
              /> */}
               <EventComponent 
                title="Friday, September 27 - Welcome Party"
                subTitle="La Manufacture Royale, Lectoure"
                content="7-10pm - Light dinner and drinks in the garden"
              />
              <EventComponent 
                title="Saturday, September 28 - Wedding"
                subTitle="Chateau Engalin, Mauvezin"
                content="
                  3pm - Shuttle from Lectoure*<br />
                  4pm - Wedding ceremony and festivities begin<br />
                  12:30am & 2am - Return shuttles*"
              />
              <EventComponent 
                title="Sunday, September 29 - Pool Party"
                subTitle="Chateau Engalin, Mauvezin"
                content="
                  1pm - Shuttle from Lectoure*<br />
                  1:30-7pm - Barbecue and pool party<br />
                  7pm - Return shuttle*
                "
              />
              <EventComponent
                content="*All shuttles will be leaving to and from Hôtel Collège des Doctrinaires in Lectoure."
              />
            </div>
          </div>
        );
        case '#stay':
        return (
          <div>
            <Back />
            <div style={{
              marginTop: "15px",
            }}>
              <EventComponent content='Guests will stay in the medieval town of Lectoure from Friday, September 27 to Monday, September 30. The welcome party on Friday night is at La Manufacture Royale, located in Lectoure. The wedding on Saturday and pool party on Sunday will be hosted at Chateau Engalin, and a shuttle will be provided to the chateau and back from Hôtel Collège des Doctrinaires.' />
              <EventComponent
                title="Hôtel Collège des Doctrinaires"
                titleLink='https://www.doctrinaires.fr'
                content="Stay at this tranquil hotel and spa in a historic building. This hotel will also be the departure point for the shuttle to Chateau Engalin on both Saturday and Sunday. To book a room, send an email to <a href='mailto:stephanie.bartontales@valvital.fr'>Stephanie</a> quoting our names."
              />
              <EventComponent
                title="La Manufacture Royale"
                titleLink='https://lamanufactureroyaledelectoure.com'
                content="Reserve one of the five rooms at this design-focused bed and breakfast, located just a 10-minute walk away from the shuttle at Hôtel Collège des Doctrinaires. To book a room, send an email to <a href='mailto:lamanufactureroyaledelectoure@gmail.com'>Christèle</a> quoting our names. Ignore the weekend availability displayed on the website, as we have rented the space for Friday’s event, and Christele will be happy to accommodate our guests."
              />
              <EventComponent
                title="L'hôtel Particulier Guilhon"
                titleLink='https://hotel-particulier-guilhon.com/en/'
                content='Book one of three suites at this boutique hotel, known for blending classic architecture and contemporary design, along with a courtyard and pool. Reserve directly via their website.'
              />
            </div>
          </div>
          )
      case '#travel':
        return (
          <div>
            <Back />
            <div style={{
              marginTop: "15px",
            }}>
              <EventComponent content="Events are planned from Friday, September 27 through Sunday evening, September 29. To fully enjoy the festivities, we recommend leaving Lectoure on Monday morning, September 30. As you make travel plans, note that Lectoure is relatively remote and about 90 minutes from Toulouse. Whether you're planning to fly in and out of Toulouse, or extending your trip, we recommend renting a car for the wedding weekend." />
              <EventComponent
                title="Flights"
                content='For those attending solely for the wedding weekend, we suggest flying straight to Toulouse, where you can rent a car for the 90-minute drive to Lectoure. For those interested in exploring more of Europe, there are plenty of direct flights from the US! Some locations within a 7-hour drive to Lectoure include Paris, Barcelona, and Nice.'
              />
              <EventComponent
                title="Car Rental"
                content='
                  When renting a car, please confirm your transmission preference as European rental options often default to stick shift. If you intend to pick up and drop off at different locations, especially across borders, look into policies beforehand. <br /><br />
                  Train travel is a beautiful experience in France, but please note that the wedding festivities are quite remote and difficult to get to by train. If you’re looking to travel by train before or after the festivities, we’re happy to discuss some of our favorite stops!
                '
              />
              <EventComponent
                title="Passports"
                content='Ensure your passport is up to date! France, among other countries, requires that US passports remain valid for at least three months beyond the departure date.'
              />
              <EventComponent
                title="Recs"
                content='Check out <a href="https://maps.app.goo.gl/5CFiW1RQpRRK85SL7" target="_blank">this map</a> with recommendations for some of our favorite stops in Toulouse and the surrounding regions.'
              />
            </div>
          </div>
        );
      case '#faq':
        return (
          <div>
            <Back />
            <div style={{
              marginTop: "15px",
            }}>
              <EventComponent
                title="Is there a dress code?"
                content="For <a href='/havefunwithit.png' target='_blank' rel='noopener noreferrer'>Friday and Saturday</a>, we suggest wearing something chic without being overly formal, in line with the vibe of Southwest France: think charming and laid back. Whether it be a crisp t-shirt and trousers, a casual jacket, a skirt or dress, have fun with it! Sunday is casual. Don’t forget a bathing suit!" />
              <EventComponent
                title="Are kids invited?"
                content="We are thrilled to include children in our event on Friday night in Lectoure, as well as at the pool party and barbecue on Sunday. However, Saturday's wedding celebration will be adults-only. If you have mentioned in your RSVP that you will be traveling with children, rest assured we will get in touch with you directly to help arrange babysitting services."
              />
              <EventComponent
                title="Am I expected to bring a present?"
                content="We respectfully request no gifts. We're overjoyed to spend this weekend with our families and good friends. If you wish to give something, we would really appreciate letters in lieu of a present!"
              />
              <EventComponent
                title="What is the weather going to be like?"
                content='During late September, weather in the Gers region tends to range between the mid 70s to low 80s. While most weekend activities are planned to be held outdoors, we have arranged for indoor alternatives in the event of unexpected rain.'
              />
            </div>
          </div>
        );
      case '#rsvp':
        return (
          <div>
            <Back />
            <div style={{
              marginTop: "15px",
            }}>
              <RSVP />
            </div>
          </div>
          )
      // case '#faq':
      //   return (
      //     <div>
      //       <Back />
      //       <br />
      //       <br />
      //       <i>rsvp information coming early 2024</i>
      //     </div>
      //   );
      case '#registry':
        return (
          <div>
            <Back />
            <br />
            <br />
            <i>registry information coming early 2024</i>
          </div>
        );

      default:
        return <Home />;
    }
  };

  return (
    <div className="App">
      <Content />
    </div>
  );
}

export default App;

interface EventComponentProps {
  title?: string;
  titleLink?: string; // Optional URL for the title
  subTitle?: string;
  content: string;
}

const EventComponent: React.FC<EventComponentProps> = ({ title, titleLink, content, subTitle }) => (
  <div className="event">
    {title && titleLink ? (
      <p className='title'><a href={titleLink} target="_blank" rel="noopener noreferrer">{title}</a></p>
    ) : title ? (
      <p className='title'>{title}</p>
    ) : null}
    {subTitle && <p className='subtitle'>{subTitle}</p>}
    {/* Using dangerouslySetInnerHTML for content including HTML links */}
    <p dangerouslySetInnerHTML={{ __html: content }}></p>
  </div>
);

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  attendFriday: string;
  attendSaturday: string;
  attendSunday: string;
  bringingChildren: string;
  dietaryRestrictions: string;
};

const RSVP: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    attendFriday: 'yes',
    attendSaturday: 'yes',
    attendSunday: 'yes',
    bringingChildren: 'no',
    dietaryRestrictions: '',
  });

  const [success, setSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeTextbox = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetch('https://script.google.com/macros/s/AKfycbxju4UDINpJauFiCBQmRytZ-D45cnP01Xef9TDozkwvFYbIjtwO0iqjbhZCQwsunyrr/exec', { // Replace YOUR_WEB_APP_URL with the URL you got from Google Apps Script
      method: 'POST',
      mode: 'no-cors', // This might be necessary to avoid CORS issues
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => setSuccess(true))
    .catch(error => console.error('Error:', error));
  };

  return (
    <form className='rsvp' onSubmit={handleSubmit}>
      <p className='title'>
        Please fill out one form per guest.
      </p>
      <div className='input'>
        <p>First name:</p>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div className='input'>
        <p>Last name:</p>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>
      <div className='input'>
        <p>Email:</p>
        <input
          type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <br />
      <div>
        <p className='title'>
          Will you be able to join us for the following events?
        </p>
      </div>
        <p>Friday, September 27th - Welcome Party</p>
        <p>La Manufacture Royale, Lectoure</p>
        <p>7-10pm</p>
        <label>
          <input
            type="radio"
            name="attendFriday"
            value="yes"
            checked={formData.attendFriday === 'yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="attendFriday"
            value="no"
            checked={formData.attendFriday === 'no'}
            onChange={handleChange}
          />
          No
        </label>
      <div>
        <p>Saturday, September 28th - Wedding</p>
        <p>Chateau Engalin, Mauvezin</p>
        <p>3:30pm-late</p>
        <label>
          <input
            type="radio"
            name="attendSaturday"
            value="yes"
            checked={formData.attendSaturday === 'yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="attendSaturday"
            value="no"
            checked={formData.attendSaturday === 'no'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      <div>
        <p>Sunday, September 29th - Pool Party</p>
        <p>Chateau Engalin, Mauvezin</p>
        <p>1-7pm</p>
        <label>
          <input
            type="radio"
            name="attendSunday"
            value="yes"
            checked={formData.attendSunday === 'yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="attendSunday"
            value="no"
            checked={formData.attendSunday === 'no'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      <br />
      <div>
        <p className='title'>Are you planning to travel with children?</p>
        <label>
          <input
            type="radio"
            name="bringingChildren"
            value="yes"
            checked={formData.bringingChildren === 'yes'}
            onChange={handleChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="bringingChildren"
            value="no"
            checked={formData.bringingChildren === 'no'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      <br />
      <div>
        <p className='title'>Do you have any dietary restrictions?</p>
        <label>
          <textarea
            name="dietaryRestrictions"
            cols={35}
            rows={5}
            onChange={handleChangeTextbox}
            value={formData.dietaryRestrictions}
          />
        </label>
      </div>
      <div className='thank-you'>
        <p>If you’re planning to join us, we understand that it involves significant travel. If you have any questions that come up about the journey, need suggestions, or have any other concerns, please do not hesitate to contact us directly! </p>
      </div>
      <br />
      {success ? <p>Thank you!</p> : <button type="submit">Submit</button>}
    </form>
  );
};
